import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CloseOutlined,
  LogoutOutlined,
  HomeFilled,
  CameraFilled,
} from "@ant-design/icons";
import { Flex, Layout, Menu, theme, Typography } from "antd";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import "./CustomLayout.css";
import { Switch } from "antd";

const { Header, Sider, Content } = Layout;

const navBarData = [
  { name: "Home", path: "/home", icon: <HomeFilled /> },
  { name: "View Plots", path: "/plots", icon: <CameraFilled /> },
];

const CustomLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [sideBarWidth, setSideBarWidth] = useState<number>(80);
  const [activeLink, setActiveLink] = useState<number>(0);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const getUrl = (linkName: string) => {
    return linkName.toLowerCase();
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const index = navBarData.findIndex((link) => link.path === currentPath);

    if (index !== -1) {
      setActiveLink(index);
    }
  }, []);

  const windowWidth = window.innerWidth;
  const updateScreen = () => {
    if (windowWidth < 600) {
      setCollapsed(true);
      setSideBarWidth(0);
    } else {
      setCollapsed(false);
      setSideBarWidth(80);
    }
  };

  useEffect(() => {
    updateScreen();
    window.addEventListener("resize", updateScreen);
    return () => {
      window.removeEventListener("resize", updateScreen);
    };
  }, []);

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={sideBarWidth}
        style={{
          height: "100vh",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "#FFF",
          borderRight: "1px solid #ccc",
        }}
        id="sider"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
          className="demo-logo-vertical"
        />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          style={{ marginTop: 20, backgroundColor: "#FFFFFF" }}
          selectedKeys={[`${activeLink}`]}
        >
          <div id="close-div">
            <CloseOutlined id="close-menu" color="#000" onClick={updateScreen} />
          </div>
          <div className="logo-textt">
            <h1 className="text-[#1732A4] font-extrabold logo-t" id={collapsed ? 'small-logo': ''}>
              DUN'S FARM DASHBOARD
            </h1>
          </div>
          {navBarData.map((link, index) => (
            <Menu.Item
              key={index.toString()}
              icon={link.icon}
              style={{ marginTop: index === 0 ? 30 : 0 }}
            >
              <NavLink
                to={link.path}
                className={({ isActive }) =>
                  isActive || activeLink === index
                    ? "side-section-1-text active"
                    : "side-section-1-text"
                }
                onClick={() => {
                  setActiveLink(index);
                  updateScreen();
                }}
              >
                {link.name}
              </NavLink>
            </Menu.Item>
          ))}
          <Menu.Item
            key="/developers"
            icon={<LogoutOutlined />}
            style={{ marginTop: 500 }}
          >
            <NavLink to="/" className="side-section-1-text">
              Logout
            </NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ position: "relative" }}>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            top: 0,
            zIndex: 99,
          }}
          id={collapsed ? "expand-header" : "content-header"}
        >
          <Flex justify="space-between" align="center">
            <div
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                cursor: "pointer",
                padding: "20px",
                margin: "5px",
                fontSize: 22,
              }}
            >
              {collapsed ? (
                windowWidth < 600 ? (
                  <div className="flex items-center mt-[-20px]">
                    <MenuUnfoldOutlined />
                    <Link
                      to="/home"
                      className="text-primary font-extrabold ml-[30px] logo-t text-[18px]"
                    >
                      DUN'S FARM DASHBOARD
                    </Link>
                  </div>
                ) : (
                  <MenuUnfoldOutlined />
                )
              ) : (
                <MenuFoldOutlined />
              )}
            </div>
          </Flex>
        </Header>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-5px",
          }}
        ></div>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
          id={collapsed ? "content-layout" : "expand-content"}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
