import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from '../pages/screens/auth/Login/Login';
import SignUp from '../pages/screens/auth/sign-up/sign-up';
import CustomLayout from '../pages/screens/main/layout/layout';
import Home from '../pages/screens/main/home/home';
import Sensors from '../pages/screens/main/sensor/sensor';
import SensorDetails from '../pages/screens/main/sensor/sensor-details';
import ForgotPassword from '../pages/screens/auth/forgot-password/forgot-password';

function Navigation() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
        <Route element={<CustomLayout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/plots" element={<Sensors />} />
            <Route path="/plots/:id" element={<SensorDetails />} />
        </Route>
          <Route path='/' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Navigation;
