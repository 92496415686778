import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { sensorData, elementTableHeaderData } from "./sensor-data";
import SensorDetailsCard from "../../../../components/SensorDetailsCard";
import { Table } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Footer from "../footer/footer";
import { BsBatteryFull, BsMoisture } from "react-icons/bs";
import { MdOutlineSync, MdAir, MdOutlineCompress } from "react-icons/md";
import { SlEnergy } from "react-icons/sl";
import { SiAirchina } from "react-icons/si";
import { FaWater, FaTemperatureHigh } from "react-icons/fa";
import { WiHumidity } from "react-icons/wi";
import { PiSunLight } from "react-icons/pi";
import { IoWaterOutline } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import {
  FaLocationDot,
  FaSolarPanel,
  FaTemperatureHalf,
} from "react-icons/fa6";
import {
  fetchPlotById,
  PlotProps,
  fetchPlotHardware,
} from "../../../../utils/controller/plotController";
import {
  formatDistanceToNowStrict,
  parseISO,
  differenceInSeconds,
} from "date-fns";
import AddElement from "./AddPlot/AddElement/AddElement";

interface PlotDetailsProps {
  id: number;
  name: string;
  current_crop_type: string;
  plot_status: string;
  last_sync_time: string;
  soil_data: any;
  environmental_data: any;
  element_data: any;
  plot_hardware: any;
}

interface Props {
  sensor_data: any;
  selected_sensor: number;
  setShowSensorDetails: (value: boolean) => void;
}

function SensorDetails() {
  const [showAddElement, setShowAddElement] = useState<boolean>(false);
  const [plotDetails, setPlotDetails] = useState<PlotDetailsProps | null>(null);
  const [hardwareData, setHardwareData] = useState<any>([]);
  const { id } = useParams();
  const plotId: any = id;
  // const navigation = useNavigation()
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  console.log("plot_id: ", plotId);

  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        const response = await fetchPlotById(plotId);
        setPlotDetails(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.error("Error fetching Plot details:", err.message);
      }
    };
    fetchPlotData();
  }, []);
  useEffect(() => {
    const fetchHardwareData = async () => {
      try {
        const response = await fetchPlotHardware();
        setHardwareData(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.error("Error fetching Plot details:", err.message);
      }
    };

    // const intervalId = setInterval(fetchHardwareData, 1000);

    // return () => clearInterval(intervalId);
    fetchHardwareData();
  }, []);
  const elements = plotDetails?.element_data
  ?.slice(-10)
  ?.sort((a: any, b: any) =>
    new Date(b?.updated_at).getTime() - new Date(a?.updated_at).getTime()
  )
  ?.map((item: any) => ({
    date: item?.updated_at?.toString().slice(0, 10) || "N/A", 
    carbon: "-",
    nitrogen: item?.element?.toString() === "1" ? item?.elementValue : "-",
    calcium: "-",
    magnesium: "-",
    potassium: item?.element?.toString() === "2" ? item?.elementValue : "-",
    sodium: "-",
    manganese: "-",
    iron: "-",
    zinc: "-",
    phosphorus: item?.element?.toString() === "3" ? item?.elementValue : "-",
  }));

  
  return (
    <div
      className="w-full flex items-center justify-center min-h-screen"
      style={{ overflowX: "hidden" }}
    >
      <div className="w-[90%] flex flex-col items-center justify-center">
        <div className="flex items-center justify-between w-full mb-[30px] pt-[30px]">
          <button
            onClick={() => navigate(-1)} 
            className="flex items-center gap-[10px]"
          >
            <ArrowLeftOutlined />
            <p className="text-[#000] font-medium">Back</p>
          </button>
        </div>
        <p className="text-[#000000] font-semibold text-[28px] text-center mb-[20px]">
        {plotDetails?.name} Sensor Data
          </p>
        <div className="w-[500px] bg-primary flex flex-col justify-center items-center p-5 rounded-[10px]">
          <p className="font-regular text-white text-[20px] w-[350px] text-center border-b border-white">
            Device Status
          </p>
          <div className="flex items-start justify-between mt-[20px] gap-[20px]">
            <div className="flex flex-col items-start">
              <div className="flex items-center gap-[5px]">
                <div
                  className={`p-[4px] rounded-full ${
                    plotDetails?.plot_status.toLowerCase() === "normal" ||
                    "good"
                      ? "bg-[#33FD22]"
                      : "bg-[#D82108]"
                  }`}
                ></div>
                <p className="font-regular text-white text-[12px]">
                  Plot Status: {plotDetails?.plot_status}
                </p>
              </div>
              <div className="flex items-center gap-[5px]">
                <BsBatteryFull color="white" size={10} />
                <p className="font-regular text-white text-[12px]">
                  Battery Status:{" "}
                  {
                    plotDetails?.plot_hardware[
                      plotDetails?.plot_hardware?.length - 1
                    ]?.batteryStatus
                  }
                </p>
              </div>
              <div className="flex items-center gap-[5px]">
                <FaLocationDot color="white" size={10} />
                <p className="font-regular text-white text-[12px]">
                  Location: Ogun state
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start">
              <div className="flex items-center gap-[5px]">
                <FaSolarPanel color="white" size={10} />
                <p className="font-regular text-white text-[12px]">
                  Solar Panel Voltages:{" "}
                  {
                    plotDetails?.plot_hardware[
                      plotDetails?.plot_hardware?.length - 1
                    ]?.solarPanelVoltage
                  }
                  {/* Solar Panel Voltage: {hardwareData[hardwareData?.length-1]?.solarPanelVoltage} */}
                </p>
              </div>
              <div className="flex items-center gap-[5px]">
                <FaTemperatureHalf color="white" size={10} />
                <p className="font-regular text-white text-[12px]">
                  Board Temperature:{" "}
                  {
                    plotDetails?.plot_hardware[
                      plotDetails?.plot_hardware?.length - 1
                    ]?.boardTemperature
                  }
                </p>
              </div>
              <div className="flex items-center gap-[5px]">
                <MdOutlineSync color="white" size={10} />
                <p className="font-regular text-white text-[12px]">
                  Last Sync:{" "}
                  {formatDistanceToNowStrict(
                    plotDetails?.last_sync_time
                      ? parseISO(plotDetails?.last_sync_time)
                      : new Date(),
                    { addSuffix: true }
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center mt-[30px]">
          <p className="text-[#000000] font-semibold text-[28px] text-center mb-[20px]">
            Soil
          </p>
          <div className="flex gap-[20px] flex-wrap justify-center">
            <SensorDetailsCard
              icon={<BsMoisture color="white" size={20} />}
              title="Soil Moisture"
              result={
                plotDetails?.soil_data?.length > 0
                  ? `${
                      plotDetails?.soil_data[plotDetails.soil_data.length - 1]
                        .moisture
                    } %`
                  : "Loading"
              }
              colour="#1EA1C6"
            />
            <SensorDetailsCard
              icon={<IoWaterOutline color="white" size={20} />}
              title="ph Level"
              result={
                plotDetails?.soil_data?.length > 0
                  ? plotDetails?.soil_data[plotDetails.soil_data.length - 1]
                      .phLevel
                  : "Loading"
              }
              colour="#1EA1C6"
            />
            <SensorDetailsCard
              icon={<SlEnergy color="white" size={20} />}
              title="Electrical Conductivity"
              result={
                plotDetails?.soil_data?.length > 0
                  ? `${
                      plotDetails?.soil_data[plotDetails.soil_data.length - 1]
                        .electricalConductivity
                    } s/m`
                  : "Loading"
              }
              colour="#1EA1C6"
            />
            <SensorDetailsCard
              icon={<FaTemperatureHigh color="white" size={20} />}
              // icon={<FaWater color="white" size={20} />}
              title="Soil Temperature"
              result={
                plotDetails?.soil_data?.length > 0
                  ? // ? `${plotDetails?.soil_data[plotDetails.soil_data.length - 1].exchangeableAcid} mol/kg`
                    `${
                      plotDetails?.soil_data[plotDetails.soil_data.length - 1]
                        .soilTemperature
                    } °C`
                  : "Loading"
              }
              colour="#1EA1C6"
            />
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center mt-[30px]">
          <p className="text-[#000000] font-semibold text-[28px] text-center mb-[20px]">
          Micro-Climate
          </p>
          <div className="flex gap-[20px] flex-wrap justify-center">
            <SensorDetailsCard
              icon={<FaTemperatureHigh color="white" size={20} />}
              title="Atmospheric Temperature"
              result={
                plotDetails?.soil_data?.length > 0
                  ? `${
                      plotDetails?.environmental_data[
                        plotDetails.environmental_data.length - 1
                      ].temperature
                    } °C`
                  : "Loading"
              }
              colour="#3D0975"
            />
            <SensorDetailsCard
              icon={<WiHumidity color="white" size={20} />}
              title="Atmospheric Humidity"
              result={
                plotDetails?.soil_data?.length > 0
                  ? `${
                      plotDetails?.environmental_data[
                        plotDetails.environmental_data.length - 1
                      ].humidity
                    } %`
                  : "Loading"
              }
              colour="#3D0975"
            />
            <SensorDetailsCard
              icon={<MdOutlineCompress color="white" size={20} />}
              title="Atmospheric Pressure"
              result={
                plotDetails?.soil_data?.length > 0
                  ? `${
                      plotDetails?.environmental_data[
                        plotDetails.environmental_data.length - 1
                      ].pressure
                    } Pa`
                  : "Loading"
              }
              colour="#3D0975"
            />
            <SensorDetailsCard
              icon={<SiAirchina color="white" size={20} />}
              title="Atmospheric VoC"
              result={
                plotDetails?.soil_data?.length > 0
                  ? `${
                      plotDetails?.environmental_data[
                        plotDetails.environmental_data.length - 1
                      ].naturalGas
                    } mol/m³`
                  : "Loading"
              }
              colour="#3D0975"
            />
            {/* <SensorDetailsCard
              icon={<PiSunLight color="white" size={20} />}
              title="Atmospheric Sunlight"
              result={
                plotDetails?.soil_data?.length > 0
                  ? `${
                      plotDetails?.environmental_data[
                        plotDetails.environmental_data.length - 1
                      ].sunlight
                    } W/m²`
                  : "Loading"
              }
              colour="#FDDFB5"
            />
            <SensorDetailsCard
              icon={<MdAir color="white" size={20} />}
              title="Atmospheric Air Quality"
              result={
                plotDetails?.soil_data?.length > 0
                  ? `${
                      plotDetails?.environmental_data[
                        plotDetails.environmental_data.length - 1
                      ].airQuality
                    } µg/m³`
                  : "Loading"
              }
              colour="#FDDFB5"
            /> */}
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center mt-[30px]">
          <p className="text-[#000000] font-semibold text-[28px] text-center mb-[20px]">
            Elements
          </p>

          <Table
            dataSource={elements}
            columns={elementTableHeaderData}
            pagination={false}
          />
          <div className="w-full flex justify-end mt-[30px]">
            <Footer />
          </div>
        </div>
      </div>
      {showAddElement && (
        <AddElement
          showAddElement={showAddElement}
          setShowAddElement={setShowAddElement}
        />
      )}
    </div>
  );
}

export default SensorDetails;
