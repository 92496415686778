import React from 'react'
import AuthLeft from '../auth-left/auth-left'
import '../Login/Login.css'
import { Link } from 'react-router-dom'
import Footer from '../../main/footer/footer'

function SignUp() {
  return (
    <div>
      <div className='login-wrapper'>
      <div className="auth-left">
        <AuthLeft />
      </div>
      <div className="auth-right">
        <div className="login-form">
          <div className="flex justify-center my-[30px]">
          <h2 className='text-[#04293A] font-extrabold text-[28px]'>DUN'S FARM DASHBOARD</h2>
          </div>
          <h1>Sign Up</h1>
          <div className="form-section">
            <label>
              Email
              <input 
                type="email" 
                placeholder='Email'
                className='login-input'
              />
            </label>
            <label>
              Password
              <input 
                type="password" 
                placeholder='xxxxxxxx'
                className='login-input'
              />
            </label>
            <label>
              Confirm Password
              <input 
                type="Confirm password" 
                placeholder='xxxxxxxx'
                className='login-input'
              />
            </label>
            <center>
              <Link to='/dashboard' className='login-btn'>
                <button className='bg-primary'>Sign Up</button>
              </Link>
              <p className='text-primary font-bold text-[14px] mt-[20px]'>Already have an account? {" "}
                <Link to='/' className='text-[#ECB365] underline'>Login</Link>
              </p>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div 
      className="mobile-login"
      // style={{backgroundImage: `url('./images/login/loginBg.webp')`}}
    >
      <img src="./images/auth-bg.png" alt="bg" className='auth-bg' />

          <div className="auth-right">
        <div className="login-form" id='mobile-login-form'>
          <div className="bin-logo-div">
          <h2 className='text-[#04293A] font-extrabold text-[14px]'>DUN'S FARM DASHBOARD</h2>
          </div>
          <h1>Sign Up</h1>
          <div className="form-section">
            <label>
              Email
              <input 
                type="email" 
                placeholder='Email'
                className='login-input'
              />
            </label>
            <label>
              Password
              <input 
                type="password" 
                placeholder='xxxxxxxx'
                className='login-input'
              />
            </label>
            <center>
              <Link to='/dashboard' className='login-btn'>
                <button className='bg-primary'>SignUp</button>
              </Link>
              <p className='text-primary font-bold text-[10px] mt-[20px]'>Already have an account? {" "}
                <Link to='/' className='text-[#ECB365] underline'>Login</Link>
              </p>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div className=' w-full flex justify-end mt-[-70px] pr-[50px] mobile-footer'>
      <Footer />
    </div>
    </div>
  )
}

export default SignUp