import React, { useEffect, useState } from "react";
// import "../AddPlot/AddPlot.css"
import { processPlotData, PlotProps } from "../../../../../../utils/controller/plotController";

interface AddPlotProps {
  showAddElement: boolean;
  setShowAddElement: (value: boolean) => void;
}

function AddElement({ showAddElement, setShowAddElement }: AddPlotProps) {
  const [id, setId] = useState<number>(0);
  const [plotName, setPlotName] = useState<string>("");
  const [cropType, setCropType] = useState<string>("");
  const [lastSync, setLastSync] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const cropOptions = [
    { value: "legium", label: "legium" },
    { value: "fibre", label: "fibre" },
    { value: "yam", label: "yam" },
    { value: "vegetable", label: "vegetable" },
    { value: "rice", label: "rice" },
  ];

  // const handleSave = async () => {
  //   // if (!cropType) {
  //   //   alert("Please fill all fields.");
  //   //   return;
  //   // }
  //   setIsLoading(true);

  //   const plotData: PlotProps = {
  //     id: id,
  //     name: plotName,
  //     current_crop_type: cropType,
  //     last_sync_time: lastSync,
  //   };

  //   try {
  //     const response = await processPlotData(plotData);
  //     const { data } = response;
  //     alert("Plot added successfully");
  //     setShowAddElement(false);
  //   } catch (err) {
  //     setError("Failed to add plot data. Please try again.");
  //     alert("Failed to save plot data. Please try again.");
  //     console.error("Error:", err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  return (
    <div className="bin-details-wrapper">
      <div className="bin-details">
        <h2 className="bin-details-heading">New Element Registration</h2>
        <div className="bin-reg-form">
          <label>
          Element Name
            <input
              type="text"
              placeholder="Element Name"
              className="reg-input"
              value={plotName}
              onChange={(e) => setPlotName(e.target.value)}
            />
          </label>
          <label>
            Element Value
            <input
              type="text"
              placeholder="Element Value"
              className="reg-input"
              value={plotName}
              onChange={(e) => setCropType(e.target.value)}
            />
          </label>
        </div>
        <div className="save-cancel-btn">
          <button
            className="save-btn"
            // onClick={handleSave}
            id={isLoading ? "disabled-btn" : ""}
          >
            {isLoading ? "Please wait" : "Save"}
          </button>
          <button
            className="cancel-btn"
            onClick={() => setShowAddElement(!showAddElement)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddElement;
