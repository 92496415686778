interface SensorProps {
  plot: string;
  status: string;
  battery: string;
  last_sync: string;
  soil: any;
  environment: any;
  elements: any;
  online: boolean;
}

export const sensorData: SensorProps[] = [
  {
    plot: "Plot 1",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 2",
    status: "Good",
    battery: "55%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
        phosphorus: 50,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 3",
    status: "Good",
    battery: "49%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 4",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 5",
    status: "Bad",
    battery: "2%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 6",
    status: "Bad",
    battery: "5%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 7",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 8",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 9",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 10",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 11",
    status: "Good",
    battery: "88%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 12",
    status: "Good",
    battery: "78%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 13",
    status: "Bad",
    battery: "44%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 14",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 15",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 16",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 17",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 18",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 19",
    status: "Bad",
    battery: "39%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 20",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 21",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 22",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 23",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 24",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 25",
    status: "Good",
    battery: "70%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 26",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 27",
    status: "Good",
    battery: "20%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 28",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
  {
    plot: "Plot 29",
    status: "Good",
    battery: "60%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 30",
    status: "Good",
    battery: "40%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: false,
  },
  {
    plot: "Plot 31",
    status: "Good",
    battery: "99%",
    last_sync: "20 secs ago",
    soil: {
      soil_moisture: "32%",
      ph_level: "42",
      electrical_conductivity: "15 s/m",
      exchangeable_acidity: "65 mol/kg",
    },
    environment: {
      temperature: "32 °C",
      humidity: "25%",
      pressure: "45 Pa",
      natural_gases: "76 mol/m³",
      sunlight: "200W/m²",
    },
    elements: [
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
      {
        date: "20/07/2024",
        carbon: 20,
        nitrogen: 56,
        calcium: 45,
        magnesium: 23,
        potassium: 11,
        sodium: 65,
        manganese: 34,
        iron: 89,
        zinc: 76,
      },
    ],
    online: true,
  },
];

export interface ElementTableHeader{
  title: string;
  dataIndex: string;
  key: string;
}

export const elementTableHeaderData: ElementTableHeader[] = [
  {
      title: '',
      dataIndex: 'date',
      key: 'date',
  },
  {
      title: 'Carbon',
      dataIndex: 'carbon',
      key: 'carbon',
  },
  {
      title: 'Nitrogen',
      dataIndex: 'nitrogen',
      key: 'nitrogen',
  },
  {
      title: 'Calcium',
      dataIndex: 'calcium',
      key: 'calcium',
  },
  {
      title: 'Magnesium',
      dataIndex: 'magnesium',
      key: 'magnesium',
  },
  {
      title: 'Potassium',
      dataIndex: 'potassium',
      key: 'potassium',
  },
  {
      title: 'Sodium',
      dataIndex: 'sodium',
      key: 'sodium',
  },
  {
      title: 'Manganese',
      dataIndex: 'manganese',
      key: 'manganese',
  },
  {
      title: 'Iron',
      dataIndex: 'iron',
      key: 'iron',
  },
  {
      title: 'Zinc',
      dataIndex: 'zinc',
      key: 'zinc',
  },
  {
      title: 'Phosphorus',
      dataIndex: 'phosphorus',
      key: 'phosphorus',
  },
]