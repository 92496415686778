import React from 'react'

function AuthLeft() {
  return (
    <div 
        className='flex h-full'
        // style={{backgroundImage: `url('./images/login/login-bg.svg')`}}
    >
        <img 
            src="./images/auth-bg.png" 
            alt="bg-pic" 
            className='w-full h-[830px] mt-[-5px] ml-[-10px]'
        />
    </div>
  )
}

export default AuthLeft