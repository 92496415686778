import React from 'react';
import './App.css';
import Navigation from './navigation';

function App() {
  return (
    <Navigation />
  );
}

export default App;
