import React from "react";

interface Props{
    img: any;
    percent: string;
    high: boolean;
    compared: string;
    children: React.ReactNode | null;
}

function OverviewCard({img, percent, high, compared, children}: Props) {
  return (
    <div className="flex-col items-center gap-[10px]">
      <div className="w-full">
      </div>
      <div className="w-[40%]">
        {children}
      </div>
    </div>
  );
}

export default OverviewCard;
