import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import OverviewCard from "../../../../components/OverviewCard";
import Footer from "../footer/footer";
import {
  fetchAllPlots,
  PlotProps,
  fetchPlotAlerts,
  fetchPlotById,
  fetchPlotAverage,
  fetchPlotSoilAverage,
  fetchPlotChartAverage,
} from "../../../../utils/controller/plotController";
import AlertCard from "../../../../components/HomeCard/HomeCard";
import "./home.css";
import GaugeChart from "react-gauge-chart";
import Modal from "../../../../components/Modal";

type ChartDataType = {
  day: string;
  Avg_temperature: number | null;
  Avg_pH: number | null;
  Avg_moisture: number | null;
  Avg_envrTemp: number | null;
  Avg_humidity: number | null;
  Avg_pressure: number | null;
}[];

interface PlotDetailsProps {
  id: number;
  name: string;
  current_crop_type: string;
  plot_status: string;
  last_sync_time: string;
  soil_data: any;
  environmental_data: any;
  element_data: any;
  plot_hardware: any;
}

interface Element {
  id: number;
  element: number;
  element_name: string;
  elementValue: string;
  plot: number;
}

function Home() {
  const [allPlots, setAllPlots] = useState<PlotProps[]>([]);
  const [plotsAvg, setPlotAvg] = useState<any>({});
  const [plotsSoilAvg, setPlotsSoilAvg] = useState<any>({});
  const [plotsChartAvg, setPlotsChartAvg] = useState<any>({});
  const [allWarnings, setAllWarnings] = useState<PlotProps[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [plotDetails, setPlotDetails] = useState<PlotDetailsProps | null>(null);
  const plotId = 1;

  const openModal = (chartType: any) => {
    setSelectedChart(chartType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedChart("");
  };
  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        const response = await fetchPlotById(plotId);
        setPlotDetails(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.error("Error fetching Plot details:", err.message);
      }
    };

    const intervalId = setInterval(fetchPlotData, 20000);

    return () => clearInterval(intervalId);
    // fetchPlotData();
  }, []);
  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        const response = await fetchAllPlots();
        setAllPlots(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.error("Error fetching Plot data:", err.message);
      }
    };
    fetchPlotData();
  }, []);
  useEffect(() => {
    const fetchPlotAlertData = async () => {
      try {
        const response = await fetchPlotAlerts();
        setAllWarnings(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.error("Error fetching Plot data:", err.message);
      }
    };
    const intervalId = setInterval(fetchPlotAlertData, 10000);

    return () => clearInterval(intervalId);
    // fetchPlotAlertData();
  }, []);

  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        const response = await fetchPlotById(plotId);
        setPlotAvg(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.error("Error fetching Plot details:", err.message);
      }
    };

    // const intervalId = setInterval(fetchPlotData, 1000);

    // return () => clearInterval(intervalId);
    fetchPlotData();
  }, []);
  useEffect(() => {
    const fetchPlotAvgData = async () => {
      try {
        const response = await fetchPlotAverage();
        setPlotAvg(response.data || {}); 
        console.log("plotAvg", response.data);
      } catch (err: any) {
        console.error("Error fetching Plot data:", err.message);
      }
    };
    const intervalId = setInterval(fetchPlotAvgData, 20000);

    return () => clearInterval(intervalId);
    // fetchPlotAvgData();
  }, []);
  useEffect(() => {
    const fetchPlotAvgSoilData = async () => {
      try {
        const response = await fetchPlotSoilAverage();
        setPlotsSoilAvg(response.data || {}); 
        console.log("plotAvg", response.data);
      } catch (err: any) {
        console.error("Error fetching Plot data:", err.message);
      }
    };
    const intervalId = setInterval(fetchPlotAvgSoilData, 20000);

    return () => clearInterval(intervalId);
    // fetchPlotAvgData();
  }, []);
  useEffect(() => {
    const fetchPlotChartData = async () => {
      try {
        const response = await fetchPlotChartAverage();
        setPlotsChartAvg(response.data || {}); // Ensure allPlots is an empty array if no data
        console.log("plotAvg", response.data);
      } catch (err: any) {
        console.error("Error fetching Plot data:", err.message);
      }
    };
    const intervalId = setInterval(fetchPlotChartData, 20000);

    return () => clearInterval(intervalId);
    // fetchPlotAvgData();
  }, []);

  const [chartsData, setChartsData] = useState<ChartDataType>([]);

  useEffect(() => {
    const fetchPlotChartData = async () => {
      try {
        const response = await fetchPlotChartAverage();
        const transformedData = transformData(response.data);
        setChartsData(transformedData);
      } catch (err: any) {
        console.error("Error fetching Plot data:", err.message);
      }
    };
    fetchPlotChartData();

    const intervalId = setInterval(fetchPlotChartData, 20000);
    return () => clearInterval(intervalId);
  }, []);

  const transformData = (data: any) => {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const fullDays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    return fullDays.map((fullDay, index) => ({
      day: days[index],
      Avg_temperature: Math.floor(
        data.soil_temperature.find((d: any) => d[fullDay])?.[fullDay]?.avg || 0
      ),
      Avg_pH: Math.floor(
        data.ph.find((d: any) => d[fullDay])?.[fullDay]?.avg || 0
      ),
      Avg_moisture: Math.floor(
        data.moisture.find((d: any) => d[fullDay])?.[fullDay]?.avg || 0
      ),
      Avg_envrTemp: Math.floor(
        data.environmental_temperature?.find((d: any) => d[fullDay])?.[fullDay]
          ?.avg || 0
      ),
      Avg_humidity: Math.floor(
        data.humidity?.find((d: any) => d[fullDay])?.[fullDay]?.avg || 0
      ),
      Avg_pressure: Math.floor(
        data.pressure?.find((d: any) => d[fullDay])?.[fullDay]?.avg || 0
      ),
    }));
  };
  
  // function calculatePercentage(value: number) {
  //   return `${value.toFixed(2)}%`;
  // }
  const yAxisTicks = [0, 25, 50, 75, 100];
  const ypHAxisTicks = [0, 14];

  function getLastValue(elementName: string): string | null {
    const filteredElements = plotDetails?.element_data?.filter(
      (el: Element) => el.element_name === elementName
    ) ?? []; 
  
    return filteredElements.length > 0
      ? filteredElements[filteredElements.length - 1].elementValue
      : null;
  }
  
  const lastNitrogenValue = Number(getLastValue("Nitrogen")?? 0).toFixed() <= '9' ? 10 : Number(getLastValue("Nitrogen"));
  const lastPhosphorusValue = Number(getLastValue("Phosphorus")?? 0).toFixed() === '9' ? Number(getLastValue("Phosphorus"))+1 : Number(getLastValue("Phosphorus"));
  const lastPotassiumValue = Number(getLastValue("Potassium")?? 0).toFixed() === '9' ? Number(getLastValue("Potassium"))+1 : Number(getLastValue("Potassium"));
  
  // console.log('env temp: ', plotDetails?.environmental_data[
  //   plotDetails.environmental_data.length - 1
  // ].environmental_temperature)
  

  return (
    <div className="min-h-screen flex-col relative">
      <div className="flex-grow">
        <div className="home-parent-div">
          <div className="avg-left">
            <div className="flex items-end text-primary home-heading">
              <h2>
                DAQfarm -<span>AI/IoT Smart Farm Management System</span>
              </h2>
            </div>
            <div className="w-full rounded-[10px] primary-btn p-[30px] flex items-end justify-between mt-[30px] banner">
              <a
                href="https://www.nigcomsat.gov.ng/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./images/home/NIGCOMSAT.png"
                  alt=""
                  className="nig-banner-img"
                />
              </a>
              <div className="flex-col items-start">
                <p className="text-[#FFF] font-bold powered">Powered by</p>
                <div className="bg-[#FFF] rounded-[10px] p-2">
                  <a
                    href="https://td4pai.org.ng"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/td4pai-logo.png"
                      alt=""
                      className="banner-img"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="flex items-center justify-between">
                <h1 className="home-subheading font-extrabold mt-[5%]">
                  Soil Data Analytics
                </h1>
                <div className="flex items-center gap-[20px] slider-arrow">
                  <img
                    src="./images/home/left.png"
                    alt="plot"
                    className="w-[15px] h-[15px]"
                  />
                  <img
                    src="./images/home/right.png"
                    alt="plot"
                    className="w-[15px] h-[15px]"
                  />
                </div>
              </div>
              <div className="data-analytics-div">
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      Temperature
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#183EA8"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            plotDetails?.soil_data[
                              plotDetails.soil_data.length - 1
                            ].soilTemperature
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        needleBaseColor="#1EA2C6"
                        animate={false}
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.soil_data !== undefined &&
                        plotDetails?.soil_data !== null
                          ? `${Number(
                              plotDetails?.soil_data[
                                plotDetails.soil_data.length - 1
                              ].soilTemperature
                            ).toFixed()} °C`
                          : "Loading"}
                      </p>
                    </div>
                    <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={true}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        onClick={() => openModal("Temperature")}
                      >
                        <ResponsiveContainer width="100%" height={"100%"}>
                          <AreaChart
                            data={chartsData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: -20,
                              bottom: 30,
                            }}
                          >
                            <XAxis
                              dataKey="day"
                              axisLine={false}
                              tickLine={false}
                              tick={false}
                            />
                            <YAxis
                              tickLine={false}
                              axisLine={false}
                              width={50}
                              tick={false}
                            />
                            <Tooltip />
                            <Area
                              type="monotone"
                              dataKey="Avg_temperature"
                              stroke="#183EA8"
                              fill="#D2ECF3"
                              strokeWidth={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                        {/* <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={chartData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: -20,
                              bottom: 30,
                            }}
                          >
                            <XAxis
                              dataKey="day"
                              axisLine={false}
                              tickLine={false}
                              tick={false}
                            />
                            <YAxis
                              ticks={yAxisTicks}
                              tickFormatter={(value) => `${value}`}
                              tickLine={false}
                              orientation="left"
                              yAxisId={0}
                              axisLine={false}
                              width={50}
                              tick={false}
                            />
                            <Tooltip />

                            <Area
                              type="monotone"
                              dataKey="Temperature"
                              stroke="#183EA8"
                              fill="#D2ECF3"
                              strokeWidth={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer> */}
                      </div>
                    </OverviewCard>
                  </div>
                </div>
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      pH Level
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#1EA2C6"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            plotDetails?.soil_data[
                              plotDetails.soil_data.length - 1
                            ].phLevel * 7
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#183EA8"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.soil_data !== undefined &&
                        plotDetails?.soil_data !== null
                          ? `${Number(
                              plotDetails?.soil_data[
                                plotDetails.soil_data.length - 1
                              ].phLevel
                            ).toFixed()}`
                          : "Loading"}
                      </p>
                    </div>
                    <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={true}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        onClick={() => openModal("pH Level")}
                      >
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={chartsData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: -20,
                              bottom: 30,
                            }}
                          >
                            <XAxis
                              dataKey="day"
                              axisLine={false}
                              tickLine={false}
                              tick={false}
                            />
                            <YAxis
                              ticks={ypHAxisTicks}
                              tickFormatter={(value) => `${value}`}
                              tickLine={false}
                              orientation="left"
                              yAxisId={0}
                              axisLine={false}
                              width={50}
                              tick={false}
                            />
                            <Tooltip />

                            <Area
                              type="monotone"
                              dataKey="Avg_pH"
                              stroke="#183EA8"
                              fill="#D2ECF3"
                              strokeWidth={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </OverviewCard>
                  </div>
                </div>
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      Soil Moisture
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#183EA8"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            plotDetails?.soil_data[
                              plotDetails.soil_data.length - 1
                            ].moisture
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#1EA2C6"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.soil_data !== undefined &&
                        plotDetails?.soil_data !== null
                          ? `${Number(
                              plotDetails?.soil_data[
                                plotDetails.soil_data.length - 1
                              ].moisture
                            ).toFixed()}%`
                          : "Loading"}
                      </p>
                    </div>
                    <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={true}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        onClick={() => openModal("Soil Moisture")}
                      >
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={chartsData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: -20,
                              bottom: 30,
                            }}
                          >
                            <XAxis
                              dataKey="day"
                              axisLine={false}
                              tickLine={false}
                              tick={false}
                            />
                            <YAxis
                              ticks={yAxisTicks}
                              tickFormatter={(value) => `${value}`}
                              tickLine={false}
                              orientation="left"
                              yAxisId={0}
                              axisLine={false}
                              width={50}
                              tick={false}
                            />
                            <Tooltip />

                            <Area
                              type="monotone"
                              dataKey="Avg_moisture"
                              stroke="#183EA8"
                              fill="#D2ECF3"
                              strokeWidth={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </OverviewCard>
                  </div>
                </div>
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      Electrical Conductivity
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#1EA2C6"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            plotDetails?.soil_data[
                              plotDetails.soil_data.length - 1
                            ].electricalConductivity
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#183EA8"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.soil_data !== undefined &&
                        plotDetails?.soil_data !== null
                          ? `${Number(
                              plotDetails?.soil_data[
                                plotDetails.soil_data.length - 1
                              ].electricalConductivity
                            ).toFixed()}`
                          : "Loading"}
                      </p>
                    </div>
                    <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={false}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        // onClick={() => openModal("Electrical Conductivity")}
                      ></div>
                    </OverviewCard>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="home-subheading font-extrabold mt-[5%]">
                  Micro-Climate Data Analytics
                </h1>
                <div className="flex items-center gap-[20px] slider-arrow">
                  <img
                    src="./images/home/left.png"
                    alt="plot"
                    className="w-[15px] h-[15px]"
                  />
                  <img
                    src="./images/home/right.png"
                    alt="plot"
                    className="w-[15px] h-[15px]"
                  />
                </div>
              </div>
              <div className="data-analytics-div">
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      Atm Temperature
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#39056C"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            plotDetails?.environmental_data[
                              plotDetails.environmental_data.length - 1
                            ].temperature
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#F92222"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.environmental_data !== undefined &&
                        plotDetails?.environmental_data !== null
                          ? `${Number(
                              plotDetails?.environmental_data[
                                plotDetails.environmental_data.length - 1
                              ].temperature
                            ).toFixed()} °C`
                          : "Loading"}
                      </p>
                    </div>
                    <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={false}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        onClick={() => openModal("Atm Temperature")}
                      >
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={chartsData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: -20,
                              bottom: 30,
                            }}
                          >
                            <XAxis
                              dataKey="day"
                              axisLine={false}
                              tickLine={false}
                              tick={false}
                            />
                            <YAxis
                              ticks={yAxisTicks}
                              tickFormatter={(value) => `${value}`}
                              tickLine={false}
                              orientation="left"
                              yAxisId={0}
                              axisLine={false}
                              width={50}
                              tick={false}
                            />
                            <Tooltip />

                            <Area
                              type="monotone"
                              dataKey="Avg_envrTemp"
                              stroke="#39056C"
                              fill="#E4D9FC"
                              strokeWidth={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </OverviewCard>
                  </div>
                </div>
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      Atm Humidity
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#7A40F2"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            plotDetails?.environmental_data[
                              plotDetails.environmental_data.length - 1
                            ].humidity
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#F92222"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.environmental_data !== undefined &&
                        plotDetails?.environmental_data !== null
                          ? `${Number(
                              plotDetails?.environmental_data[
                                plotDetails.environmental_data.length - 1
                              ].humidity
                            ).toFixed()}%`
                          : "Loading"}
                      </p>
                    </div>
                    <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={true}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        onClick={() => openModal("Atm Humidity")}
                      >
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={chartsData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: -20,
                              bottom: 30,
                            }}
                          >
                            <XAxis
                              dataKey="day"
                              axisLine={false}
                              tickLine={false}
                              tick={false}
                            />
                            <YAxis
                              ticks={yAxisTicks}
                              tickFormatter={(value) => `${value}`}
                              tickLine={false}
                              orientation="left"
                              yAxisId={0}
                              axisLine={false}
                              width={50}
                              tick={false}
                            />
                            <Tooltip />

                            <Area
                              type="monotone"
                              dataKey="Avg_humidity"
                              stroke="#39056C"
                              fill="#E4D9FC"
                              strokeWidth={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </OverviewCard>
                  </div>
                </div>
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      Atm Pressure
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#F92222"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            plotDetails?.environmental_data[
                              plotDetails.environmental_data.length - 1
                            ].pressure
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#7A40F2"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.environmental_data !== undefined &&
                        plotDetails?.environmental_data !== null
                          ? `${Number(
                              plotDetails?.environmental_data[
                                plotDetails.environmental_data.length - 1
                              ].pressure
                            ).toFixed()}Pa`
                          : "Loading"}
                      </p>
                    </div>
                    <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={false}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        onClick={() => openModal("Atm Pressure")}
                      >
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart
                            data={chartsData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: -20,
                              bottom: 30,
                            }}
                          >
                            <XAxis
                              dataKey="day"
                              axisLine={false}
                              tickLine={false}
                              tick={false}
                            />
                            <YAxis
                              ticks={yAxisTicks}
                              tickFormatter={(value) => `${value}`}
                              tickLine={false}
                              orientation="left"
                              yAxisId={0}
                              axisLine={false}
                              width={50}
                              tick={false}
                            />
                            <Tooltip />

                            <Area
                              type="monotone"
                              dataKey="Avg_pressure"
                              stroke="#39056C"
                              fill="#E4D9FC"
                              strokeWidth={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </OverviewCard>
                  </div>
                </div>
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      Air Quality (VoC)
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#39056C"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            plotDetails?.environmental_data[
                              plotDetails.environmental_data.length - 1
                            ].naturalGas
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#F92222"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.environmental_data !== undefined &&
                        plotDetails?.environmental_data !== null
                          ? `${Number(
                              plotDetails?.environmental_data[
                                plotDetails.environmental_data.length - 1
                              ].naturalGas
                            ).toFixed()}mol/m³`
                          : "Loading"}
                      </p>
                    </div>
                    <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={true}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        // onClick={() => openModal("Natural Gases")}
                      ></div>
                    </OverviewCard>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="home-subheading font-extrabold mt-[5%]">
                  NPK(Nitrogen, Potassium & Phosphorous)
                </h1>
                <div className="flex items-center gap-[20px] slider-arrow">
                  <img
                    src="./images/home/left.png"
                    alt="plot"
                    className="w-[15px] h-[15px]"
                  />
                  <img
                    src="./images/home/right.png"
                    alt="plot"
                    className="w-[15px] h-[15px]"
                  />
                </div>
              </div>
              <div className="data-analytics-div">
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                      Nitrogen
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#183EA8"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            lastNitrogenValue
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        needleBaseColor="#1EA2C6"
                        animate={false}
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.element_data !== undefined &&
                        plotDetails?.element_data !== null
                          ? `${Number(
                            getLastValue("Nitrogen")
                            ).toFixed()}`
                          : "Loading"}
                      </p>
                    </div>
                    {/* <OverviewCard
                      img={"./images/home/first.png"}
                      percent="15%"
                      high={true}
                      compared="last week"
                    >
                      <div
                        className="w-[200px] h-[150px] overview-chart"
                        onClick={() => openModal("Temperature")}
                      >
                        <ResponsiveContainer width="100%" height={"100%"}>
                          <AreaChart
                            data={chartsData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: -20,
                              bottom: 30,
                            }}
                          >
                            <XAxis
                              dataKey="day"
                              axisLine={false}
                              tickLine={false}
                              tick={false}
                            />
                            <YAxis
                              tickLine={false}
                              axisLine={false}
                              width={50}
                              tick={false}
                            />
                            <Tooltip />
                            <Area
                              type="monotone"
                              dataKey="Avg_temperature"
                              stroke="#183EA8"
                              fill="#D2ECF3"
                              strokeWidth={1}
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </OverviewCard> */}
                  </div>
                </div>
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                    Potassium
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#1EA2C6"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            lastPotassiumValue
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#183EA8"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.soil_data !== undefined &&
                        plotDetails?.soil_data !== null
                          ? `${Number(
                              getLastValue("Potassium")
                            ).toFixed()}`
                          : "Loading"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="shadow bg-[#FFF] rounded-[10px] gauge-wrapper p-2 mt-[20px]">
                  <div className="w-[200px] flex-col items-center justify-center">
                    <h1 className="text-[14px] font-extrabold mt-[5%] text-center">
                    Phosphorus
                    </h1>
                    <div className="w-full flex items-center justify-center">
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={1}
                        colors={["#183EA8"]}
                        arcWidth={0.1}
                        percent={Number(
                          `0.${Number(
                            lastPhosphorusValue
                          ).toFixed()}`
                        )}
                        marginInPercent={0.1}
                        arcPadding={0}
                        textColor="transparent"
                        style={{ fontSize: "10px", height: "100%" }}
                        cornerRadius={20}
                        needleColor="#000"
                        animate={false}
                        needleBaseColor="#1EA2C6"
                      />
                    </div>
                    <div className="w-full flex-col items-center mt-[-18%]">
                      <p className="text-lg font-bold text-center">
                        {plotDetails?.soil_data !== undefined &&
                        plotDetails?.soil_data !== null
                          ? `${Number(
                              getLastValue("Phosphorus")
                            ).toFixed()}`
                          : "Loading"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="alert-right">
            <div className="w-full p-2 bg-[#FFF] rounded-[10px]">
              <p className="text-[#F92222] font-bold">Alerts!!!</p>
              {allWarnings?.map((warning, index) => (
                <AlertCard key={index} allWarnings={warning} />
              ))}
            </div>
            <div className="w-full rounded-[10px] primary-btn p-4 flex justify-between mt-[30px]">
              <div>
                <p className="text-[#FFF] font-bold">Need help?</p>
                <p className="text-[#FFF] font-regular">
                  Contact technical support
                </p>
              </div>
              <div className="bg-primary rounded-[15px] p-[3px_5px] flex items-center justify-center">
                <a
                  href="mailto:td4pai@gmail.com"
                  className="text-[#FFF] font-medium"
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <Modal
            chartData={chartsData}
            closeModal={closeModal}
            openModal={openModal}
            yAxisTicks={
              selectedChart === "pH Level" ? ypHAxisTicks : yAxisTicks
            }
            selectedChart={selectedChart}
          />
        )}
      </div>
      <div className="w-full flex justify-end">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
