import React from "react";
import "./HomeCard.css";
import { LuClock3 } from "react-icons/lu";
import { CgCalendarDates } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

interface Props {
  allWarnings: any;
}


function AlertCard({ allWarnings }: Props) {
  const navigate = useNavigate();
  return (
    <div
      className={`shadow rounded-[20px] h-[200px] w-full p-4 bg-primary home-card mt-[20px]`}
    >
      <div className="flex justify-between w-full">
        <p className="bg-[#2E46AD] text-[#FFF] font-medium p-[5px_15px] rounded-[20px]">
        {allWarnings?.warnings[allWarnings?.warnings.length - 1]?.title}
        </p>
        <p className="bg-[#1EA1C6] text-[#FFF] font-medium p-[5px_8px] rounded-[20px] cursor-pointer text-"
        onClick={() => navigate(`/plots/${allWarnings?.id}`)}
        >
          View more
        </p>
      </div>

      <div className="w-full flex-col items-center mt-[20px]">
        <p className="text-[#F92222] font-bold text-center">Problem Detected</p>
        <p className="text-[#FFF] font-medium text-small text-center mt-[10px]">
          {allWarnings?.warnings[allWarnings?.warnings.length - 1]?.message}
        </p>

        <div className="w-[100%] flex justify-between mt-[20px] px-[10px]">
          <div className="flex items-center gap-[5px]">
            <img
              src="./images/plot.png"
              alt="plot"
              className="w-[15px] h-[15px]"
            />
            <p className="text-[#FFF] font-regular text-small">{allWarnings?.name}</p>
          </div>
          <div className="flex items-center gap-[5px]">
            <LuClock3 color="white" size={15} />
            <p className="text-[#FFF] font-regular text-small">{allWarnings?.last_sync_calculated_time} ago</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertCard;
