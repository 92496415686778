import React from 'react'
import AuthLeft from '../auth-left/auth-left'
import './Login.css'
import { Link } from 'react-router-dom'
import Footer from '../../main/footer/footer'

function Login() {
  return (
    <div className='login-parent'>
      <div className='login-wrapper'>
      <div className="auth-left">
        <AuthLeft />
      </div>
      <div className="auth-right">
        <div className="login-form">
          <div className="bin-logo-div">
            <h2 className='text-[#04293A] font-extrabold text-[28px]'>DUN'S FARM DASHBOARD</h2>
            {/* <img src="./images/nav/bin-logo.svg" alt="logo" className="bin-logo" /> */}
          </div>
          <h1>Log in</h1>
          <div className="form-section">
            <label>
              Email
              <input 
                type="email" 
                placeholder='Email'
                className='login-input'
              />
            </label>
            <label>
              Password
              <input 
                type="password" 
                placeholder='xxxxxxxx'
                className='login-input'
              />
            </label>
           <div className='flex w-full justify-end mt-[-40px]'>
                <Link to='/forgot-password' className='text-primary font-bold text-[14px]'>Forgot Password?</Link>
           </div>
            <center>
              <Link to='/home' className='login-btn'>
                <button className='bg-primary'>Log In</button>
              </Link>
              <p className='text-primary font-bold text-[14px] mt-[20px]'>Don't have an account? {" "}
                <Link to='/signup' className='text-[#ECB365] underline'>Sign up</Link>
              </p>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div 
      className="mobile-login"
      // style={{backgroundImage: `url('./images/login/loginBg.webp')`}}
    >
      <img src="./images/auth-bg.png" alt="bg" className='auth-bg' />
    <div className="auth-right">
        <div className="login-form" id='mobile-login-form'>
          <div className="bin-logo-div">
          <h2 className='text-[#04293A] font-extrabold text-[14px]'>DUN'S FARM DASHBOARD</h2>
          </div>
          <h1>Log in</h1>
          <div className="form-section">
            <label>
              Email
              <input 
                type="email" 
                placeholder='Email'
                className='login-input'
              />
            </label>
            <label>
              Password
              <input 
                type="password" 
                placeholder='xxxxxxxx'
                className='login-input'
              />
            </label>
           <div className='flex w-full justify-end mt-[-20px] mb-[20px]'>
                <Link to='/forgot-password' className='text-[#ECB365] underline font-bold text-[10px]'>Forgot Password?</Link>
           </div>
            <center>
              <Link to='/home' className='login-btn'>
                <button className='bg-primary'>Log In</button>
              </Link>
              <p className='text-primary font-bold text-[10px] mt-[20px]'>Don't have an account? {" "}
                <Link to='/signup' className='text-[#ECB365] underline'>Sign up</Link>
              </p>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div className=' w-full flex justify-end mt-[-70px] pr-[50px] mobile-footer'>
      <Footer />
    </div>
    </div>
  )
}

export default Login