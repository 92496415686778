import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { 
  Pagination,
  PaginationProps,
  ConfigProvider,
  Typography, 
} from "antd"; 
import Footer from "../footer/footer";
import AddPlot from "./AddPlot/AddPlot";
import {
  fetchAllPlots,
  PlotProps,
} from "../../../../utils/controller/plotController"; 

function Sensors() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddPlot, setShowAddPlot] = useState<boolean>(false);
  const [allPlots, setAllPlots] = useState<PlotProps[]>([]);

  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        const response = await fetchAllPlots();
        setAllPlots(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.error("Error fetching Plot data:", err.message);
      }
    };

    const intervalId = setInterval(fetchPlotData, 10000);

    return () => clearInterval(intervalId);
    // fetchPlotData()
  }, []);

  const location = useLocation();
  const windowWidth = window.innerWidth;
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
  useEffect(() => {
    const page = new URLSearchParams(location.search).get("page") || 1;
    setCurrentPage(Number(page));
  }, [location.search]);
  const noOfItemsPerPage = () => {
    if (screenSize <= 1050) {
      return 15;
    } else if (screenSize <= 768) {
      return 9;
    } else if (screenSize <= 578) {
      return 18;
    } else {
      return 20;
    }
  };
  const itemsPerPage = noOfItemsPerPage();
  const arrayLength = allPlots.length;
  const totalPage = Math.ceil(arrayLength / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const paginationSize = windowWidth < 600 ? "small" : "default";
  const { Text, Title } = Typography;
  function disableButton(buttonTitle: string) {
    let disable = false;
    if (buttonTitle === "Previous") {
      disable = currentPage === 1;
      return disable;
    } else if (buttonTitle === "Next") {
      disable = currentPage === totalPage;
      return disable;
    }
    return disable;
  }
  const disablePrevious = disableButton("Previous");
  const disableNext = disableButton("Next");
  const showPagination = arrayLength > itemsPerPage && !isLoading;

  const handlePagination: PaginationProps["onChange"] = (pageNumber) => {
    setCurrentPage(pageNumber);
    navigate(`/sensors?page=${pageNumber}`);
  };
  // const itemRender: PaginationProps["itemRender"] = (
  //   number,
  //   type,
  //   originalElement
  // ) => {
  //   if (type === "prev") {
  //     return (
  //       <button
  //         disabled={disablePrevious}
  //         className={`pagination-but ${disablePrevious && "disable-button"}`}
  //       >
  //         Previous
  //       </button>
  //     );
  //   }
  //   if (type === "next") {
  //     return (
  //       <button
  //         disabled={disableNext}
  //         className={`pagination-but ${disableNext && "disable-button"}`}
  //       >
  //         Next
  //       </button>
  //     );
  //   }
  //   return (
  //     <div
  //       style={{
  //         border: currentPage !== number ? "1px solid grey" : "none",
  //         borderRadius: currentPage !== number ? "5px" : "",
  //       }}
  //     >
  //       {originalElement}
  //     </div>
  //   );
  // };
  // const closeWhiteSpace = (item: string) =>
  //   item.toLowerCase().replace(/\s/g, "");
  // const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
  //   current,
  //   pageSize
  // ) => {
  //   console.log(current, pageSize);
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="w-[100%] min-h-screen flex flex-col">
  <div className="flex-grow">
    
        <div className="flex items-center justify-between mb-[30px]">
          <p className="text-[#000000] font-semibold text-[28px]">Plots Data</p>
          <div className="login-btn">
            <button
              className="bg-red-600 gap-[10px]"
              onClick={() => setShowAddPlot(true)}
            >
              <img
                src="./images/add.svg"
                alt=""
                className="w-[20px] h-[20px]"
              />
              Add a new plot
            </button>
          </div>
        </div>
        <div>
          <section>
            {isLoading ? (
              <div className="no-result-con">
                <div className="custom-loader" />
              </div>
            ) : !isLoading && allPlots.length < 1 ? (
              <div className="flex items-center justify-center no-result-con">
                <Title level={2} className="no-result-text">
                  No plot available{" "}
                </Title>
              </div>
            ) : (
              <div className="gift-items-con grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 cursor-pointer">
                {allPlots.slice(startIndex, endIndex).reverse().map((item, index) => (
                  <div
                    key={index}
                    className="flex items-start gap-2 w-[271px] shadow rounded-lg h-[100px] bg-white"
                    onClick={() => navigate(`/plots/${item.id}`)}
                  >
                    <img
                      src="./images/plot-img.png"
                      alt=""
                      className="w-[50px] h-[50px] pl-2"
                    />
                    <div className="w-full p-2">
                      <p className="text-lg text-black font-semibold">
                        {item.name}
                      </p>
                      <p className="text-sm text-black">Status:</p>
                      <p className="text-xs text-black">Battery Status:</p>
                      <div className=" flex items-end justify-end ml-[10px] w-[99%] mt-[3px]">
                        <div className="flex items-center gap-[5px] bg-primary p-[5px] rounded-l-[20px]">
                          

                          <h4
                            className="text-[10px] font-extrabold text-[#FFFFFF]"
                            onClick={() => navigate(`/sensors/${index + 1}`)}
                          >
                            View more
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </section>
        </div>
        {showPagination && (
          <div className="pagination-section flex justify-between mt-[30px]">
            <Text className="text-black font-p-semibold text-[16px]">
              Showing {Intl.NumberFormat().format(allPlots.length)} result
              {allPlots.length > 1 && "s"}
            </Text>
            <div className="pagination-Con">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#ffffff",
                    borderRadius: 5,
                    colorBgContainer: "#04293A",
                  },
                }}
              >
                <Pagination
                  total={arrayLength}
                  // itemRender={itemRender}
                  onChange={handlePagination}
                  pageSize={itemsPerPage}
                  current={currentPage}
                  showSizeChanger={false}
                  size={paginationSize}
                />
              </ConfigProvider>
            </div>
          </div>
        )}
      {showAddPlot && (
        <AddPlot showAddPlot={showAddPlot} setShowAddPlot={setShowAddPlot} />
      )}
  </div>
      <div className="w-full flex justify-end">
        <Footer />
      </div>
</div>

  );
}

export default Sensors;
