import axios from "axios";
import { PublicApi } from "../api/api";

export interface PlotProps {
  id: number;
  name: string;
  current_crop_type: string;
  last_sync_time: string;
}
export const processPlotData = async (plotData: PlotProps) => {
  try {
    const response = await PublicApi.post("/plot/", {
      name: plotData.name,
      current_crop_type: plotData.current_crop_type,
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error("Error response:", error.response.data);
    }
    throw error;
  }
};

export const fetchAllPlots = async () => {
  try {
    const response = await PublicApi.get("/plot/");
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchPlotById = async (id: number) => {
  try {
    const response = await PublicApi.get(`/plot-detail/${id}/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchPlotHardware = async () => {
  try {
    const response = await PublicApi.get("/hardware/");
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchPlotAlerts = async () => {
  try {
    const response = await PublicApi.get("/warning/");
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchPlotAverage = async () => {
  try {
    const response = await PublicApi.get("/average-environment-filter/");
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchPlotSoilAverage = async () => {
  try {
    const response = await PublicApi.get("/average-soil-data-filter/");
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchPlotChartAverage = async () => {
  try {
    const response = await PublicApi.get("/average-data-per-day/");
    return response;
  } catch (error) {
    throw error;
  }
};

const elements = [
  {
    id: 20121,
    element: 1,
    element_name: "Nitrogen",
    elementValue: "9.00",
    plot: 1,
  },
  {
    id: 20122,
    element: 2,
    element_name: "Phosphorus",
    elementValue: "13.00",
    plot: 1,
  },
  {
    id: 20123,
    element: 3,
    element_name: "Potassium",
    elementValue: "32.00",
    plot: 1,
  },
  {
    id: 20124,
    element: 1,
    element_name: "Nitrogen",
    elementValue: "9.00",
    plot: 1,
  },
  {
    id: 20125,
    element: 2,
    element_name: "Phosphorus",
    elementValue: "13.00",
    plot: 1,
  },
  {
    id: 20126,
    element: 2,
    element_name: "Potassium",
    elementValue: "13.00",
    plot: 1,
  },
];