import React from 'react'
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Tooltip, Area } from 'recharts'

interface Props{
    closeModal: ()=>void;
    openModal: (value: string)=>void;
    chartData: any;
    yAxisTicks: any;
    selectedChart: string;  
  }


function Modal({chartData, closeModal, openModal, yAxisTicks, selectedChart}:Props) {
  const getDataKey = () => {
    switch (selectedChart) {
      case "pH Level":
        return "Avg_pH";
      case "Soil Moisture":
        return "Avg_moisture";
      case "Temperature":
        return "Avg_temperature";
      case "Atm Temperature":
        return "Avg_envrTemp";
      case "Atm Temperature":
        return "Avg_envrTemp";
      case "Atm Humidity":
        return "Avg_humidity";
      case "Atm Pressure":
        return "Avg_pressure";
      default:
        return selectedChart; 
    }
  };
  return (
    <div className="w-full fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg p-6 w-[90%] max-w-lg">
      <div className="flex justify-end">
        <button
          onClick={closeModal}
          className="font-extrabold text-[#000] px-4 py-2 rounded-lg"
          style={{ alignSelf: "center" }}
        >
          X
        </button>
      </div>
      <div
        className="w-[100%] h-[300px] overview-chart"
        onClick={() => openModal(getDataKey())}
      >
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: -20,
              bottom: 30,
            }}
          >
            <XAxis
              dataKey="day"
              axisLine={false}
              tickLine={false}
              // tick={false}
            />
            <YAxis
              ticks={yAxisTicks}
              tickFormatter={(value) => `${value}`}
              tickLine={false}
              orientation="left"
              yAxisId={0}
              axisLine={false}
              width={50}
              // tick={false}
            />
            <Tooltip />

            <Area
              type="monotone"
              dataKey={getDataKey()}
              stroke="#183EA8"
              fill="#FFF"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
  )
}

export default Modal