import React from "react";

interface Props{
    icon?: any;
    title: string;
    result: string;
    colour: string;
}

function SensorDetailsCard({icon, title, result, colour}: Props) {
  return (
    <div className={`shadow rounded-md h-[168px] w-[150px] p-4 flex-col items-start justify-between mb-[20px]`} style={{backgroundColor: '#fff'}}>
      <div className="rounded-full w-[50px] h-[50px] flex items-center justify-center" style={{backgroundColor: colour}}>
        {icon}
      </div>
      <p className="text-black font-regular text-[12px] mt-[15px]">{title}</p>
      <p className="text-black font-bold text-[16px] mt-[15px]">{result}</p>
    </div>
  );
}

export default SensorDetailsCard;
