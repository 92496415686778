import React from "react";

function Footer() {
  return (
    <div className="mt-auto py-4 text-center">
      <div className="flex items-center justify-center gap-5">
        <p className="text-xs font-normal text-black">
          Copyright &copy; 2024. DAQfarm Powered by TD4PAI
          <a
            href="https://td4pai.org.ng"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline mx-1"
          >
            https://td4pai.org.ng
          </a>
          <span>+2348072277317</span>
          . All Rights Reserved.
        </p>
        <img src="./images/td4pai-logo.png" alt="" style={{width: 100, height: 50}} />
      </div>
    </div>
  );
}

export default Footer;
